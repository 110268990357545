<template>
<section class="solution_m">
   <img class="banner" :src="$t('locale')=='zh'?banner:banner_en" alt="" />
   <ul>
      <p v-html="$t('PC.Solution.nbxgrj')"></p>
      <li class="frist_li">
         <img src="../../assets/zh/images-mobile//fangan_img1@2x.png" alt="" />
         <div class="content_box">
            <p class="title">{{ $t("PC.Solution.dmlqae") }}</p>
            <p class="text">
               {{ $t("PC.Solution.lv198h") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../assets/zh/images-mobile//fangan_img2@2x.png" alt="" />
         <div class="content_box">
            <p class="title">{{ $t("PC.Solution.1uf1y4") }}</p>
            <p class="text">
               {{ $t("PC.Solution.cz13x4") }}
            </p>
         </div>
      </li>
      <li>
         <img src="../../assets/zh/images-mobile//fangan_img3@2x.png" alt="" />
         <div class="content_box">
            <p class="title">{{ $t("PC.Solution.htlg8e") }}</p>
            <p class="text">
               {{ $t("PC.Solution.kmbou7") }}
            </p>
         </div>
      </li>
   </ul>
   <FooterB></FooterB>
</section>
</template>

<script>
import FooterB from "./components/FooterB";
export default {
   components: {
      FooterB,
   },
   data() {
      return {
         banner: require('../../assets/zh/images-mobile//fangan_banner@2x.png'),
         banner_en: require('../../assets/en/images-mobile//fangan_banner@2x.png'),
      }
   }
};
</script>

<style lang="scss" scoped>
.solution_m {
   margin-top: 48px;
   word-break: break-word !important;

   img.banner {
      width: 100%;
      height: 150px;
   }

   ul {
      box-sizing: border-box;
      width: 100%;
      background-color: #f5f6f9;
      padding-bottom: 16px;

      &>p {
         width: 100%;
         text-align: center;
         font-size: 12px;
         color: #333;
         line-height: 24px;
         padding: 20px;
         padding-bottom: 16px;
         background-color: #fff;
      }

      li {
         box-sizing: border-box;
         text-align: left;
         padding: 0 20px 16px;

         img {
            width: 334px;
            height: 133px;
         }

         .content_box {
            background-color: #fff;
            box-sizing: border-box;
            padding: 16px;
            padding-top: 12px;

            p.title {
               color: #333;
               font-size: 14px;
               line-height: 20px;
               padding-bottom: 8px;
               text-align: center;
               font-weight: 600;
            }

            .text {
               color: #666;
               font-size: 12px;
               line-height: 18px;
               width: 100%;
               font-size: 12px;
               line-height: 21px;
            }

            .more {
               display: inline-block;
               margin-top: 12px;
               width: 72px;
               height: 25px;
               border: 1px solid #666666;
               font-size: 10px;
               font-family:   PingFangSC-Regular, PingFang SC;
               font-weight: 400;
               color: #666666;
               line-height: 24px;
               text-align: center;
            }
         }
      }

      li.frist_li {
         padding-top: 16px;
      }
   }
}
</style>
